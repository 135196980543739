<template>
	<div>
		<div class="box1">
			<div class="box2">
				<div class="box4">
					<div class="box5">
						<img src="../../assets/login/image4.png" >
					</div>
					<div class="box6">
						<p>皮诺客CRM后台管理系统</p>
						<span>Pinnoocle  CRM Management System </span>
					</div>
				</div>
				<div class="box3">
					<div class="titlebox">
						<div class="title">
							<span>欢迎{{type == 'login' ? '登陆' : '注册'}}</span>
							<div></div>
						</div>
					</div>
					<div class="" v-if="type == 'login'">
						<div class="inputbox">
							<img src="../../assets/login/image3.png" />
							<input v-model="admin" placeholder="请输入管理员姓名" />
						</div>
						<div class="inputbox">
							<img src="../../assets/login/image1.png" />
							<input @keyup.enter="clickLogin" v-model="password" type="password" placeholder="请输入登录密码" />
						</div>
					<!-- 	<div class="zhucetext" @click="goZhuce">
							账号注册
						</div> -->
					</div>
					<!-- <div class="" v-if="type == 'registered'"> -->
					<div class="" v-if="false">
						<div class="inputbox">
							<img src="../../assets/login/image3.png" />
							<input v-model="admin" placeholder="请输入手机号" />
						</div>
						<div class="inputbox">
							<img src="../../assets/login/image1.png" />
							<input @keyup.enter="clickLogin" v-model="password" type="password" placeholder="请输入登录密码" />
						</div>
						<div class="inputbox">
							<img src="../../assets/login/image2.png" />
							<input placeholder="请输入验证码" />
							<div>发送验证码</div>
						</div>
						<div class="text11">
							注册即表示你阅读并接受<span @click="showYingsi">《用户协议》</span>
						</div>
						<div class="zhucetext" @click="goDenglu">
							登录
						</div>
					</div>

					
					<div class="loginbtn flex" @click="clickLogin" v-if="type === 'login'">登 陆</div>
					<div class="loginbtn flex" v-if="type === 'registered'">注 册</div>
				</div>
			</div>
		</div>
		<ying-si ref="yingSi"></ying-si>
	</div>
</template>

<script>
	import yingSi from "./yingsiZhengce.vue"
import jsSHA from 'jssha';
import { login } from '@/network/api.js';
export default {
	data() {
		return {
			// type: 'registered',
			type: 'login',
			admin: '',
			password: '',
			timestamp: ''
		};
	},
	name: 'Login',
	components: {
		yingSi
	},
	methods: {
		// 点击出现隐私协议
		showYingsi() {
			this.$refs.yingSi.dialogVisible = true
		},
		// 转变登录
		goDenglu() {
			this.type = 'login'
		},
		// 转变注册
		goZhuce() {
			this.type = 'registered'
		},
		submit() {
			console.log(1111);
		},
		//  密码加密--sha512
		getPassword(password) {
		
			const shaObj = new jsSHA('SHA-512', 'TEXT', { encoding: 'UTF8' });
			shaObj.update(password);
			console.log(shaObj.getHash('HEX'));
			var timestamp = new Date().getTime();

			const pas = shaObj.getHash('HEX') + timestamp;
			this.timestamp = timestamp;
			const shaObj1 = new jsSHA('SHA-512', 'TEXT', { encoding: 'UTF8' });
			shaObj1.update(pas);
			return shaObj1.getHash('HEX');
			// return shaObj.getHash("HEX")
		},
		// 点击登录
		clickLogin() {
			if (!this.admin || !this.password) return this.$myMessage('请输入账号或密码', 'error');
			
			let time = Math.ceil(+new Date() / 1000);
			let passw = this.getPassword(this.password);
			let loginInfo = {
				loginName: this.admin,
				loginPassword: passw,
				timestamp: this.timestamp
			};
			this.$store.dispatch('_login', loginInfo).then(res => {
				this.$myMessage('登录成功', 'success');
				this.$store.dispatch('_userPermissionTree')
				
				this.$router.replace({
					path: '/home'
				});
			});
		}
	}
};
</script>

<style scoped lang="scss">
	.text11 {
		margin: 0 0 1.25em 0;
		span {
			cursor: pointer;
			color: #3266F7;
		}
	}
	.zhucetext {
		cursor: pointer;
		font-size: 1.25em;
		font-family: PingFang-SC-Medium, PingFang-SC;
		font-weight: 500;
		color: #3266F7;
	}
	.box6 {
		position: absolute;
		bottom: 2.375em;
		right: 1.75em;
		display: flex;
		align-items: flex-end;
		flex-direction: column;
		p {
			font-size: 1.625em;
			font-family: PingFang-SC-Heavy, PingFang-SC;
			font-weight: 600;
			color: #FFFFFF;
		}
		span {
			margin: 1em 0 0 0;
			font-size: 1.375em;
			font-family: PingFang-SC-Heavy, PingFang-SC;
			font-weight: 500;
			color: #FFFFFF;
		}
	}
	.box5 {
		padding: 2.5em 1.875em;
		img {
			width: 11.25em;
			height: 3.25em;
		}
	}
	.box4 {
		width: 53%;
		position: relative;
	}
.loginbtn:active {
	opacity: 0.6;
}
.loginbtn {
	margin: 3.125em 0 0 0;
	cursor: pointer;
	width: 25em;
	height: 3.75em;
	background-color: #2e77f6;
	color: #ffffff;
	border-radius: 1.8em;
}
.titlebox {
	width: 25em;
	display: flex;
	justify-content: flex-start;
}
.inputbox {
	width: 25em;
	display: flex;
	align-items: center;
	padding: 0 0 1em 0;
	border-bottom: 2px solid #e7e7e7;
	margin: 0 0 2.6875em 0;
	img {
		width: 1.8125em;
		height: 1.9375em;
	}
	input {
		outline: none;
		border: 0;
		font-size: 1.125em;
		width: 15.625em;
		padding: 0 0 0 1.25em;
	}
	div {
		width: 6.25em;
		height: 2em;
		background: #2e77f6;
		border-radius: 0.25em;
		display: flex;
		align-items: center;
		justify-content: center;
		color: #ffffff;
		font-size: 0.875em;
		cursor: pointer;
		margin-left: 2.25em;
	}
	div:active {
		opacity: 0.6;
	}
}
@media screen and (max-width: 1920px) {
	.box1 {
		font-size: 16px;
	}
}
@media screen and (max-width: 1720px) {
	.box1 {
		font-size: 15px;
	}
}
@media screen and (max-width: 1520px) {
	.box1 {
		font-size: 14px;
	}
}
@media screen and (max-width: 1320px) {
	.box1 {
		font-size: 13px;
	}
}
@media screen and (max-width: 1120px) {
	.box1 {
		font-size: 11px;
	}
}
.box1 {
	width: 100vw;
	height: 100vh;
	display: flex;
	align-items: center;
	justify-content: center;
	// background-color: rgba(72, 83, 116);
	background-size: 100% 100%;
	background-image: url(../../assets/login/image5.png);
}
.box2 {
	min-width: 1000px;
	width: 92vw;
	height: 94vh;
	background-color: rgba(46, 119, 246, 0.2);
	display: flex;
	justify-content: space-between;
}
.box3 {
	width: 47%;
	height: 94vh;
	background-color: #ffffff;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
}
.title {
	font-family: PingFangSC-Medium, PingFang SC;
	font-weight: 500;
	color: #111111;
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	margin: 0 0 4.375em 0;
	span {
		font-size: 2.125em;
	}
	div {
		width: 6.25em;
		height: 0.3125em;
		background: #2e77f6;
		border-radius: 0.1875em;
	}
}
</style>
