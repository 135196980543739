<template>
	<div class="addtan">
		<el-dialog :show-close="false" width="600px" :visible.sync="dialogVisible" :before-close="clickCancel">
			<div class="tanbox">
				<div class="titlebox">
					<span>隐私协议</span>
					<i @click="clickCancel" class="el-icon-close" style="color: #000000;font-size: 20px;"></i>
				</div>
				<div class="formbox">
					<div class="allcontent">
						欢迎您使用魔睛数企的服务！
						为使用魔睛数企的服务，您应当阅读并遵守《用户协议》（以下简称“本协议”）。请您务必审慎阅读、充分理解各条款内容，特别是免除或者限制责任的条款、管辖与法律适用条款，以及开通或使用某项服务的单独协议。除非您已阅读并接受本协议所有条款，否则您无权使用魔睛数企提供的服务。您使用魔睛数企的服务即视为您已阅读并同意上述协议的约束。
						如果您未满18周岁，请在法定监护人的陪同下阅读本协议，并特别注意未成年人使用条款。
						一、协议的范围
						1.1 本协议是您与魔睛数企之间关于用户使用魔睛数企相关服务所订立的协议。“魔睛数企”是指安徽皮诺客网络科技有限公司及其相关服务可能存在的运营关联单位。“用户”是指使用魔睛数企相关服务的使用人，在本协议中更多地称为“您”。
						1.2 本协议项下的服务是指魔睛数企向用户提供的包括但不限于信息交流等产品及服务（以下简称“本服务”）。
						1.3 本协议内容同时包括《隐私政策》, 且您在使用魔睛数企某一特定服务时，该服务可能会另有单独的协议、相关业务规则等（以下统称为“单独协议”）。上述内容一经正式发布，即为本协议不可分割的组成部分，您同样应当遵守。您对前述任何业务规则、单独协议的接受，即视为您对本协议全部的接受。
						二、帐号与密码安全
						2.1 您在使用魔睛数企的服务时可能需要注册一个帐号。
						2.2 魔睛数企特别提醒您应妥善保管您的帐号和密码。当您使用完毕后，应安全退出。因您保管不善可能导致遭受盗号或密码失窃，责任由您自行承担。
						三、用户个人信息保护
						3.1 保护用户个人信息是魔睛数企的一项基本原则。魔睛数企将按照本协议及《隐私政策》的规定收集、使用、储存和分享您的个人信息。本协议对个人信息保护规定的内容与上述《隐私政策》有相冲突的，及本协议对个人信息保护相关内容未作明确规定的，均应以《隐私政策》的内容为准。
						3.2 您在注册帐号或使用本服务的过程中，需要填写一些必要的信息。若国家法律法规有特殊规定的，您需要填写真实的身份信息。若您填写的信息不完整，则无法使用本服务或在使用过程中受到限制。
						3.3 一般情况下，您可随时浏览、修改自己提交的信息，但出于安全性和身份识别（如号码申诉服务）的考虑，您可能无法修改注册时提供的初始注册信息及其他验证信息。
						3.4 魔睛数企将运用各种安全技术和程序建立完善的管理制度来保护您的个人信息，以免遭受未经授权的访问、使用或披露。
						3.5 魔睛数企不会将您的个人信息转移或披露给任何非关联的第三方，除非：
						（1）相关法律法规或法院、政府机关要求；
						（2）为完成合并、分立、收购或资产转让而转移；
						（3）为提供您要求的服务所必需。
						3.6 魔睛数企在未损害您的名誉亦不泄漏您商业秘密的前提下，魔睛数企可将您的使用情况无偿作为客户案例，进行相关市场宣传拓展活动。
						四、使用本服务的方式
						4.1 除非您与魔睛数企另有约定，您同意本服务仅在To B企业场景下使用。
						4.2 您应当通过魔睛数企提供或认可的方式使用本服务。您依本协议条款所取得的权利不可转让。
						4.3 您不得使用未经魔睛数企授权的插件、外挂或第三方工具对本协议项下的服务进行干扰、破坏、修改或施加其他影响。
						五、按现状提供服务
						您理解并同意， 魔睛数企的服务是按照现有技术和条件所能达到的现状提供的。魔睛数企会尽最大努力向您提供服务，确保服务的连贯性和安全性；但魔睛数企不能随时预见和防范法律、技术以及其他风险，包括但不限于不可抗力、病毒、木马、黑客攻击、系统不稳定、第三方服务瑕疵、政府行为等原因可能导致的服务中断、数据丢失以及其他的损失和风险。
						六、自备设备
						6.1 您应当理解，您使用魔睛数企的服务需自行准备与相关服务有关的终端设备（如电脑、调制解调器等装置），并承担所需的费用（如电话费、上网费等费用）。
						6.2 您理解并同意，您使用本服务时会耗用您的终端设备和带宽等资源。
						七、广告
						7.1 您同意魔睛数企可以在提供服务的过程中自行或由第三方广告商向您发送广告、推广或宣传信息（包括商业与非商业信息），其方式和范围可不经向您特别通知而变更。
						7.2 魔睛数企可能为您提供选择关闭广告信息的功能，但任何时候您都不得以本协议未明确约定或魔睛数企未书面许可的方式屏蔽、过滤广告信息。
						7.3 魔睛数企依照法律的规定对广告商履行相关义务，您应当自行判断广告信息的真实性并为自己的判断行为负责，除法律明确规定外，您因依该广告信息进行的交易或前述广告商提供的内容而遭受的损失或损害，魔睛数企不承担责任。
						7.4 您同意，对魔睛数企服务中出现的广告信息，您应审慎判断其真实性和可靠性，除法律明确规定外，您应对依该广告信息进行的交易负责。
						八、收费服务
						8.1 魔睛数企的部分服务是以收费方式提供的，如您使用收费服务，请遵守相关的协议。
						8.2 魔睛数企可能根据实际需要对收费服务的收费标准、方式进行修改和变更，魔睛数企也可能会对部分免费服务开始收费。前述修改、变更或开始收费前，魔睛数企将在相应服务页面进行通知或公告。如果您不同意上述修改、变更或付费内容，则应停止使用该服务。
						九、第三方提供的交易服务
						您在魔睛数企平台上使用第三方提供的服务进行交易时，除遵守本协议约定外，还应遵守第三方的交易协议。魔睛数企和第三方对可能出现的纠纷在法律规定和约定的范围内各自承担责任。
						十、基于软件提供服务
						若魔睛数企依托“软件”向您提供服务，您还应遵守以下约定：
						10.1 您在使用本服务的过程中可能需要下载软件，对于这些软件，魔睛数企给予您一项个人的、不可转让及非排他性的许可。您仅可为访问或使用本服务的目的而使用这些软件。
						10.2 为了改善用户体验、保证服务的安全性及产品功能的一致性，魔睛数企可能会对软件进行更新。您应该将相关软件更新到最新版本，否则魔睛数企并不保证其能正常使用。
						10.3 魔睛数企可能为不同的终端设备开发不同的软件版本，您应当根据实际情况选择下载合适的版本进行安装。您可以直接从魔睛数企的网站上获取软件，也可以从得到魔睛数企授权的第三方获取。如果您从未经魔睛数企授权的第三方获取软件或与软件名称相同的安装程序，魔睛数企无法保证该软件能够正常使用，并对因此给您造成的损失不予负责。
						10.4 除非魔睛数企书面许可，您不得从事下列任一行为：
						（1）删除软件及其副本上关于著作权的信息；
						（2）对软件进行反向工程、反向汇编、反向编译，或者以其他方式尝试发现软件的源代码；
						（3）对魔睛数企拥有知识产权的内容进行使用、出租、出借、复制、修改、链接、转载、汇编、发表、出版、建立镜像站点等；
						（4）对软件或者软件运行过程中释放到任何终端内存中的数据、软件运行过程中客户端与服务器端的交互数据，以及软件运行所必需的系统数据，进行复制、修改、增加、删除、挂接运行或创作任何衍生作品，形式包括但不限于使用插件、外挂或非经魔睛数企授权的第三方工具/服务接入软件和相关系统；
						（5）通过修改或伪造软件运行中的指令、数据，增加、删减、变动软件的功能或运行效果，或者将用于上述用途的软件、方法进行运营或向公众传播，无论这些行为是否为商业目的；
						（6）通过非魔睛数企开发、授权的第三方软件、插件、外挂、系统，登录或使用魔睛数企软件及服务，或制作、发布、传播非魔睛数企开发、授权的第三方软件、插件、外挂、系统。
						十一、知识产权声明
						11.1 魔睛数企在本服务中提供的内容（包括但不限于网页、文字、图片、音频、视频、图表等）的知识产权归魔睛数企所有，用户在使用本服务中所产生的内容的知识产权归用户或相关权利人所有。
						11.2 除另有特别声明外，魔睛数企提供本服务时所依托软件的著作权、专利权及其他知识产权均归魔睛数企所有。
						11.3 魔睛数企在本服务中所使用的商业标识，其著作权或商标权归魔睛数企所有。
						11.4 上述及其他任何本服务包含的内容的知识产权均受到法律保护，未经魔睛数企、用户或相关权利人书面许可，任何人不得以任何形式进行使用或创造相关衍生作品。
						十二、用户违法行为
						12.1 您在使用本服务时须遵守法律法规，不得利用本服务从事违法违规行为，包括但不限于：
						（1）发布、传送、传播、储存危害国家安全统一、破坏社会稳定、违反公序良俗、侮辱、诽谤、淫秽、暴力以及任何违反国家法律法规的内容；
						（2）发布、传送、传播、储存侵害他人知识产权、商业秘密等合法权利的内容；
						（3）恶意虚构事实、隐瞒真相以误导、欺骗他人；
						（4）发布、传送、传播广告信息及垃圾信息；
						（5）冒用他人名义使用本服务；
						（6）从事不法交易行为， 如洗钱、恐怖融资、贩卖枪支、毒品、禁药、盗版软件、黄色淫秽物品、其他本公司认为不得使用本服务进行交易的物品等；
						（7）非法使用他人银行账户（包括信用卡账户）或无效银行账户（包括信用卡账户）交易；
						（8）从事任何可能含有电脑病毒或是可能侵害本服务系统、资料之行为；
						（9）其他法律法规禁止的行为或魔睛数企有正当理由认为不适当之行为。
						12.2 如果您违反了本条约定，相关国家机关或机构可能会对您提起诉讼、罚款或采取其他制裁措施，并要求魔睛数企给予协助。造成损害的，您应依法予以赔偿，魔睛数企不承担任何责任。
						12.3 如果魔睛数企发现或收到他人举报您发布的信息违反本条约定，魔睛数企有权进行独立判断并采取技术手段予以删除、屏蔽或断开链接。同时，魔睛数企有权视用户的行为性质，采取包括但不限于暂停或终止服务，限制、冻结或终止账号使用，追究法律责任等措施。
						12.4 您违反本条约定，导致任何第三方损害的，您应当独立承担责任；魔睛数企因此遭受损失的，您也应当一并赔偿。
						十三、遵守当地法律监管
						13.1 您在使用本服务过程中应当遵守当地相关的法律法规，并尊重当地的道德和风俗习惯。如果您的行为违反了当地法律法规或道德风俗，您应当为此独立承担责任。
						13.2 您应避免因使用本服务而使魔睛数企卷入政治和公共事件，否则魔睛数企有权暂停或终止对您的服务。
						十四、产品风险提示
						14.1 您明确知道魔睛数企是通过互联网向您提供服务的，若互联网故障会导致魔睛数企无法正常向您提供服务。魔睛数企不对互联网故障、延迟等原因导致的信息延迟、错误负责。
						14.2 您在使用魔睛数企产品过程中，对于魔睛数企提供的信息数据内容，您不应将其视作魔睛数企明示、默示地承诺信息全部符合实际情况。
						14.3 您必须保护好自己软件和交易账户的用户名与密码，魔睛数企对因个人原因导致密码被盗的后果不承担负责。
						十五、不可抗力及其他免责事由
						15.1 您理解并同意，在使用本服务的过程中，可能会遇到不可抗力等风险因素，使本服务发生中断。不可抗力是指不能预见、不能克服并不能避免且对一方或双方造成重大影响的客观事件，包括但不限于自然灾害如洪水、地震、瘟疫流行和风暴等以及社会事件如战争、动乱、政府行为等。出现上述情况时，魔睛数企将努力在第一时间与相关单位配合，及时进行修复，但是由此给您造成的损失魔睛数企在法律允许的范围内免责。
						15.2 在法律允许的范围内，魔睛数企对以下情形导致的服务中断或受阻不承担责任：
						（1）受到计算机病毒、木马或其他恶意程序、黑客攻击的破坏；
						（2）用户或魔睛数企的电脑软件、系统、硬件和通信线路出现故障；
						（3）用户操作不当；
						（4）用户通过非魔睛数企授权的方式使用本服务；
						（5）其他魔睛数企无法控制或合理预见的情形。
						15.3 您理解并同意，在使用本服务的过程中，可能会遇到网络信息或其他用户行为带来的风险，魔睛数企不对任何信息的真实性、适用性、合法性承担责任，也不对因侵权行为给您造成的损害负责。这些风险包括但不限于：
						（1）来自他人匿名或冒名的含有威胁、诽谤、令人反感或非法内容的信息；
						（2）因使用本协议项下的服务，遭受他人误导、欺骗或其他导致或可能导致的任何心理、生理上的伤害以及经济上的损失；
						（3）其他因网络信息或用户行为引起的风险。
						15.4 您理解并同意，本服务并非为某些特定目的而设计，包括但不限于核设施、军事用途、医疗设施、交通通讯等重要领域。如果因为软件或服务的原因导致上述操作失败而带来的人员伤亡、财产损失和环境破坏等，魔睛数企不承担法律责任。
						15.5 魔睛数企依据本协议约定获得处理违法违规内容的权利，该权利不构成魔睛数企的义务或承诺，魔睛数企不能保证及时发现违法行为或进行相应处理。
						15.6 在任何情况下，您不应轻信借款、索要密码或其他涉及财产的网络信息。涉及财产操作的，请一定先核实对方身份，并请经常留意魔睛数企有关防范诈骗犯罪的提示。
						十六、协议的生效与变更
						16.1 您点击或确认注册魔睛数企并使用魔睛数企的服务即视为您已阅读本协议并接受本协议的约束。
						16.2 魔睛数企有权在必要时修改本协议条款。您可以在相关服务页面查阅最新版本的协议条款。
						16.3 本协议条款变更后，如果您继续使用魔睛数企提供的软件或服务，即视为您已接受修改后的协议。如果您不接受修改后的协议，应当停止使用魔睛数企提供的软件或服务。
						十七、服务的变更、中断、终止
						17.1 魔睛数企可能会对服务内容进行变更，也可能会中断、中止或终止服务。
						17.2 您理解并同意，魔睛数企有权自主决定经营策略。在魔睛数企发生合并、分立、收购、资产转让时，魔睛数企可向第三方转让本服务下相关资产；魔睛数企也可在单方通知您后，将本协议下部分或全部服务转交由第三方运营或履行。具体受让主体以魔睛数企通知的为准。
						17.3 如发生下列任何一种情形，魔睛数企有权不经通知而中断或终止向您提供的服务：
						（1）根据法律规定您应提交真实信息，而您提供的个人资料不真实、或与注册时信息不一致又未能提供合理证明；
						（2）您违反相关法律法规或本协议的约定；
						（3）按照法律规定或主管部门的要求；
						（4）出于安全的原因或其他必要的情形。
						17.4 魔睛数企有权按本协议8.2条的约定进行收费。若您未按时足额付费，魔睛数企有权中断、中止或终止提供服务。
						17.5 您有责任自行备份存储在本服务中的数据。如果您的服务被终止，魔睛数企可以从服务器上永久地删除您的数据,但法律法规另有规定的除外。服务终止后，魔睛数企没有义务向您返还数据。
						十八、管辖与法律适用
						18.1 本协议的成立、生效、履行、解释及纠纷解决，适用中华人民共和国大陆地区法律（不包括冲突法）。
						18.2 本协议签订地为中华人民共和国安徽省合肥市。
						18.3 若您和魔睛数企之间发生任何纠纷或争议，首先应友好协商解决；协商不成的，您同意将纠纷或争议提交本协议签订地（即中国安徽省合肥市）有管辖权的人民法院管辖。
						18.4 本协议所有条款的标题仅为阅读方便，本身并无实际涵义，不能作为本协议涵义解释的依据。
						18.5 本协议条款无论因何种原因部分无效或不可执行，其余条款仍有效，对双方具有约束力。
						十九、未成年人使用条款
						19.1 若用户未满18周岁，则为未成年人，应在监护人监护、指导下阅读本协议和使用本服务。
						19.2 未成年人用户涉世未深，容易被网络虚象迷惑，且好奇心强，遇事缺乏随机应变的处理能力，很容易被别有用心的人利用而又缺乏自我保护能力。因此，未成年人用户在使用本服务时应注意以下事项，提高安全意识，加强自我保护：
						（1）认清网络世界与现实世界的区别，避免沉迷于网络，影响日常的学习生活；
						（2）填写个人资料时，加强个人保护意识，以免不良分子对个人生活造成骚扰；
						（3）在监护人或老师的指导下，学习正确使用网络；
						（4）避免陌生网友随意会面或参与联谊活动，以免不法分子有机可乘，危及自身安全。
						19.3 监护人、学校均应对未成年人使用本服务时多做引导。特别是家长应关心子女的成长，注意与子女的沟通，指导子女上网应该注意的安全问题，防患于未然。
						二十、竞争行为限制
						若您存在以下行为：
						1）研发、经营的产品及服务与魔睛数企提供的产品与服务属于同一性质；
						2）模仿、抄袭、爬取魔睛数企产品功能、设计理念；
						3）代理与魔睛数企提供产品性质相同或相似的产品；即视为魔睛数企禁止合作的竞争对手，魔睛数企有权自上述情形发生之日起单方解除合同或无条件关停您的账号。
						二十一、其他
						如果您对本协议或本服务有意见或建议，可与魔睛数企客户服务部门联系，我们会给予您必要的帮助。（感谢阅读—完）
						安徽皮诺客网络科技有限公司

					</div>
					<div class="twobtn">
						<div @click="clickCancel">取消</div>
						<div class="save" @click="clickCancel">确认</div>
					</div>
				</div>
				
			</div>
			
			
		</el-dialog>
	</div>
</template>

<script>
	import { addCustomerChance } from "@/network/api.js"
export default {
	data() {
		return {
			dialogVisible: false,
			addinfo: {
				chanceDesc: '',		//商机描述
				chanceTitle: '',		//商机标题
				customerId: '',			//客户id
				docList: [],		//报价or设计方案文档
			}
		};
	},
	methods: {
		// 点击取消
		clickCancel() {
			this.addinfo = {
				chanceDesc: '',		//商机描述
				chanceTitle: '',		//商机标题
				customerId: '',			//客户id
				docList: [],		//报价or设计方案文档
			}
			this.dialogVisible = false
		},
		// 新增客户商机
		async _addCustomerChance() {
			if (!this.addinfo.chanceTitle) return this.$myMessage('请输入商机标题')
			if (!this.addinfo.chanceDesc) return this.$myMessage('请输入商机描述')
			const res = await addCustomerChance(this.addinfo)
			this.$myMessage('添加成功','success')
			this.clickCancel()
			this.$emit("updataInfo")
		},
	}
};
</script>

<style scoped lang="scss">
	::v-deep {
		.el-dialog{
			margin-top: 5vh !important;
		}
	}
	::-webkit-scrollbar {
		width: 0.375em;
		background-color: rgb(255, 255, 255);
	}
	::-webkit-scrollbar-thumb {
		border-radius: 0.1875em;
		background-color: rgb(172, 172, 172);
	}
	.allcontent {
		max-height: 65vh;
		overflow: scroll;
	}
	.twobtn {
		display: flex;
		align-items: center;
		justify-content: center;
		margin: 20px 0 0 0;
		div:active {
			opacity: 0.6;
		}
		div {
			cursor: pointer;
			width: 180px;
			height: 40px;
			background: #F7F7F7;
			border-radius: 4px;
			display: flex;
			align-items: center;
			justify-content: center;
			font-size: 14px;
			font-family: PingFangSC-Regular, PingFang SC;
			font-weight: 400;
			color: #999999;
			&.save {
				background: linear-gradient(270deg, #2D74F4 0%, #3286FF 100%);
				color: #ffffff;
				margin: 0 0 0 50px;
			}
		}
	}
	textarea:focus {
		border: 1px solid #4D91FF;
	}
	textarea {
		font-size: 14px;
		outline: none;
		padding: 13px 20px;
		width: 100%;
		height: 90px;
		background: #FFFFFF;
		border-radius: 4px;
		border: 1px solid #EEEEEE;
		max-width: 100%;
		min-width: 100%;
		min-height: 50px;
	}
	.inputtitle {
		margin: 0 0 15px 0;
		display: flex;
		align-items: center;
		font-size: 14px;
		font-family: PingFang-SC-Medium, PingFang-SC;
		font-weight: 500;
		color: #666666;
		span {
			color: #ff0000;
			margin: 0 10px 0 0;
		}
	}
.formbox {
	
	width: 100%;
	padding: 30px 40px;
	input:focus {
		border: 1px solid #4D91FF;
	}
	input {
		padding: 0 20px;
		width: 100%;
		outline: none;
		border: 1px solid #EEEEEE;
		height: 40px;
		font-size: 14px;
		border-radius: 4px;
	}
}
	@media screen and (max-width: 1920px) {
		.addtan {
			font-size: 16px;
		}
	}
	@media screen and (max-width: 1720px) {
		.addtan {
			font-size: 15px;
		}
	}
	@media screen and (max-width: 1520px) {
		.addtan {
			font-size: 14px;
		}
	}
	@media screen and (max-width: 1320px) {
		.addtan {
			font-size: 13px;
		}
	}
	@media screen and (max-width: 1120px) {
		.addtan {
			font-size: 12px;
		}
	}
	.titlebox {
		border-bottom: 1px solid #EEEEEE;;
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: space-between;
		height: 52px;
		padding: 0 20px;
		i {
			cursor: pointer;
		}
		span {
			font-size: 1.125rem;
			font-family: PingFang-SC-Medium, PingFang-SC;
			font-weight: 500;
			color: #303030;
		}
	}
	::v-deep {
		.el-dialog__header {
			padding: 0;
		}
		.el-dialog__body {
			padding: 0;
		}
		.el-dialog {
			border-radius: 10px;
		}
	}
</style>
